export const getStrapiCarousel = async (collectionId) => {
  const collection = await (await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_STRAPI_KEY}`
    }
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        serviceCarouselTitle: attributes?.title,
        carouselServices: attributes?.carousel_services?.data.map(({ attributes, id }) => ({
          id: id,
          title: attributes?.title,
          image: process.env.REACT_APP_STRAPI_URL + attributes?.service_carousel_image?.data?.attributes?.url
        }))
      }
    }
  )

  return values
}
