import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll'
import { getStrapiServicesList } from '../data/getStrapiServicesList'

const Services = () => {
  const [servicesData, setServicesData] = useState([])
  const collectionData = 'services-list-wraps?populate[services_lists][populate]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiServicesList(collectionData)
      setServicesData(data)
    }
    fetchData()
  }, [])

  const CardServices = ({ name, subtitle, image, index }) => {
    switch ((index % 2)) {
      case 0:
        return (
          <div key={index} className='shadow-xl md:max-h-96 bg-neutral-200 dark:bg-slate-700 my-10 rounded-xl mb-14'>
            <div className='grid sm:md:grid-cols-3 grid-cols-1'>
              <div className='flex items-center flex-col justify-center col-span-2'>
                <p className='sm:md:text-3xl text-xl font-light text-blue-900 dark:text-sky-500 py-2'>{name}</p>
                <p className='sm:md:text-xl text-md text-blue-900 dark:text-sky-400 py-2'>{subtitle}</p>
              </div>
              <img
                className='max-h-96 rounded-xl w-full  shadow-xl sm:md:scale-105 scale-100'
                src={image}
                alt=''
              />
            </div>
          </div>
        )
      case 1:
        return (
          <div key={index} className='shadow-xl md:max-h-96 bg-neutral-200 dark:bg-slate-700 rounded-xl mb-14'>
            <div className='grid sm:md:grid-cols-3 grid-cols-1'>
              <img
                className='max-h-96 rounded-xl w-full shadow-xl sm:md:scale-105 scale-100'
                src={image}
                alt=''
              />
              <div className='flex items-center flex-col justify-center col-span-2'>
                <p className='sm:md:text-3xl text-xl font-light text-blue-900 dark:text-sky-500 py-2'>{name}</p>
                <p className='sm:md:text-xl text-md text-blue-900 dark:text-sky-400 py-2'>{subtitle}</p>
              </div>
            </div>
          </div>
        )
      default:
    }
  }

  return (
    <div className='w-full bg-neutral-100 dark:bg-slate-800' id='services'>
      {servicesData?.map(({id, serviceContent, serviceSubtitle, serviceTitle, serviceCards}, index) => (
        <div key={id} className='mx-auto my-20 container'>
          <div className='grid justify-items-center w-full text-center sm:md:my-10 my-2'>
            <p className='sm:md:text-3xl text-xl font-light text-blue-900 dark:text-sky-500 py-2'>{serviceTitle}</p>
            <p className='sm:md:text-xl text-md text-blue-900 dark:text-sky-400 py-2'>{serviceSubtitle}</p>
            <p key={index} className='sm:md:text-sm text-xs sm:md:px-10 px-5 text-blue-900/80 dark:text-sky-400 py-2'>{serviceContent}</p>
          </div>
          <div className='flex items-center justify-center'>
            <Link
              spy
              smooth
              to='contact'
              className='animate-bounce duration-0 delay-0 shadow-xl text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
            >
              Contact
            </Link>
          </div>
          {serviceCards.map((attributes, id) => (
            <CardServices key={attributes.id} name={attributes.cardTitle} subtitle={attributes.cardSubtitle} image={attributes.cardImage} index={id} />
          ))}
        </div>
      ))}
    </div>
  )
}

export default Services
