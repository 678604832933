export const getStrapiServiceCards = async (collectionId) => {
  const collection = await (await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_STRAPI_KEY}`
    }
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        cardServiceTitle: attributes?.card_service_title,
        squareBackground: attributes?.square_background,
        cardServiceIcon: process.env.REACT_APP_STRAPI_URL +attributes?.card_service_icon?.data?.attributes?.url,
      }
    }
  )

  return values
}
